import Script from "next/script";
import { FC, useCallback, useEffect, useMemo } from "react";
import { SkinVersions } from "@finbackoffice/site-server-core";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import Link from "next/link";
import Img from "components/base/img/Img";
import styles from "../footer.module.sass";

type License = {
    id: string;
    imgSize: string;
    imgType: string;
    gcb_id?: string;
};

const LICENSE: Record<string, License> = {
    [SkinVersions.Betmidas]: {
        id: "bc8e03fc-ca61-4e17-8bb9-5f7ccfdc5429",
        gcb_id: "ZXlKcGRpSTZJamxxY0hsWUwyZElXbWd4WWtaSkx6SkViRzV1WVZFOVBTSXNJblpoYkhWbElqb2lVVlo2VUVadU1pOVFlbmhWU21GVFZWbFVRV2t2VVQwOUlpd2liV0ZqSWpvaU1HWTRZelZrWkRCaFlXVm1ZbVF4Tm1Zd016Tm1ZMlExWVRFNE1HRTJNalF6TUdZMVpqa3lNak15T0dKaE4yUmhORFZqWm1GaFlUWTJZVFUwTUdJek15SXNJblJoWnlJNklpSjk=",
        imgSize: "90",
        imgType: "basic-light-large",
    },
    [SkinVersions.Xrpbet]: {
        id: "96cf5f60-ea14-4901-88d4-b4f68d3c6203",
        imgSize: "128",
        imgType: "basicsmall",
    },
    [SkinVersions.Netpix]: {
        id: "59c26595-adf1-4531-b5e9-9ab8b10b33a5",
        gcb_id: "ZXlKcGRpSTZJbEV2U2xCamMxRk5VMWs1U1dObk0yTTNPRE5RUTJjOVBTSXNJblpoYkhWbElqb2ljbUp3UzJ4eFExSm9kRnBuVmxWYWJWa3pSMVZKUVQwOUlpd2liV0ZqSWpvaU9EVTFaVEptTlRCaE56VXlORE14WlRObU9XWXhNVE5oWTJZNE9EVTBabVF5T0dZNVpqVmtOMkZoTTJZd1lUazFPV0UzTkdVek1qaGtNelpqWXprME1DSXNJblJoWnlJNklpSjk=",
        imgSize: "150",
        imgType: "basic-light-large",
    },
    [SkinVersions.Chronos]: {
        id: "7095d2f9-81d7-4491-8173-353523e360c0",
        imgSize: "128",
        imgType: "basic-small",
    },
};

const LicenseLogo: FC = () => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const license = LICENSE.hasOwnProperty(COMMON_SITE_CONFIGS.skinVersion)
        ? LICENSE[COMMON_SITE_CONFIGS.skinVersion]
        : null;
    const windowScriptAntillephone = `apg_${license?.id.replaceAll("-", "_")}`;
    const windowScriptAnjour = `anj_${license?.id.replaceAll("-", "_")}`;

    useEffect(() => {
        if (
            [SkinVersions.Betmidas, SkinVersions.Netpix].includes(
                COMMON_SITE_CONFIGS.skinVersion as SkinVersions,
            ) &&
            typeof window[windowScriptAntillephone as any] !== "undefined"
        ) {
            (window[windowScriptAntillephone as any] as any)?.init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            [SkinVersions.Xrpbet, SkinVersions.Chronos].includes(
                COMMON_SITE_CONFIGS.skinVersion as SkinVersions,
            ) &&
            typeof window[windowScriptAnjour as any] !== "undefined"
        ) {
            (window[windowScriptAnjour as any] as any)?.init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAntillephoneScriptLoad = useCallback(() => {
        (window[windowScriptAntillephone as any] as any)?.init();
    }, [windowScriptAntillephone]);

    const onAnjouanGamingScriptLoad = useCallback(() => {
        (window[windowScriptAnjour as any] as any)?.init();
    }, [windowScriptAnjour]);

    const renderContent = useMemo(() => {
        if (
            license &&
            [SkinVersions.Betmidas, SkinVersions.Netpix].includes(
                COMMON_SITE_CONFIGS.skinVersion as SkinVersions,
            )
        ) {
            return (
                <div className={styles.licenseLogoBetmidas}>
                    <div
                        id={`apg-${license.id}`}
                        data-apg-seal-id={license.id}
                        data-apg-image-size={license.imgSize}
                        data-apg-image-type={license.imgType}
                    />
                    <Script
                        src={`https://${license.id}.snippet.antillephone.com/apg-seal.js`}
                        onLoad={onAntillephoneScriptLoad}
                    />
                    {license.gcb_id && (
                        <Link
                            href={`https://cert.gcb.cw/certificate?id=${license.gcb_id}`}
                            className={styles.betmidasLink}>
                            <Img
                                source={`${ASSETS_URL}/common/footer/gcb_license_logo.png`}
                                alt="gcb_license"
                                width={150}
                                height={85}
                            />
                        </Link>
                    )}
                </div>
            );
        }

        if (COMMON_SITE_CONFIGS.skinVersion === SkinVersions.Betvivo) {
            return (
                <a
                    href="https://verification.curacao-egaming.com/validateview.aspx?domain=betvivo.com"
                    target="_blank"
                    className={styles.licenseLogoBetvivo}>
                    <Img
                        source="https://verification.curacao-egaming.com/validate.ashx?domain=betvivo.com"
                        alt=""
                        width={0}
                        height={0}
                        style={{ width: "auto", height: "80px" }}
                    />
                </a>
            );
        }

        if (
            license &&
            [SkinVersions.Xrpbet, SkinVersions.Chronos].includes(
                COMMON_SITE_CONFIGS.skinVersion as SkinVersions,
            )
        ) {
            return (
                <>
                    <div
                        id={`anj-${license.id}`}
                        data-anj-seal-id={license.id}
                        data-anj-image-size={license.imgSize}
                        data-anj-image-type={license.imgType}
                    />
                    <Script
                        src={`https://${license.id}.snippet.anjouangaming.org/anj-seal.js`}
                        onLoad={onAnjouanGamingScriptLoad}
                    />
                </>
            );
        }

        return null;
    }, [
        license,
        COMMON_SITE_CONFIGS.skinVersion,
        onAntillephoneScriptLoad,
        ASSETS_URL,
        onAnjouanGamingScriptLoad,
    ]);

    return renderContent;
};

export default LicenseLogo;
